import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

//保存货主认证信息
export const saveGoodsOwnerAuthInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/ConsInfo/SaveEnterPriselist`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//查询货主认证信息
export const getGoodsOwnerAuthInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/ConsInfo/GetEnterPriselist`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

//上传法人身份证(正面)
export const uploadLegalPersonIDCardOn = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/ConsInfo/CardFace`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

//上传法人身份证(反面)
export const uploadLegalPersonIDCardBack = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/ConsInfo/CardBack`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

//上传营业执照(原件三合一正本)
export const uploadBusinessLicense = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/ConsInfo/businessLicense`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

//上传道路经营许可证
export const uploadConveyLicence = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Driver/ConveyLicence`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

//上传企业银行卡 || 法人银行卡
export const uploadLegalPersonBankCard = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/ConsInfo/Bankcard`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

//其他证件
export const uploadOherCertificate = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/ConsInfo/OherCertificate`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 上传开户许可证
export const upAccountLicence = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/ConsInfo/AccountLicenceUrlImg`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//
//
// 分割线
//
//
// 服务商端接口

// 网络货运道路运输经营许可证
export const uploadbusinessLicense = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/ConsInfo/netrtbusinessLicense`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 代开专票试点企业资格
export const uploadInvoiceLicense = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/ConsInfo/NetFTSpecialInvoiceLicense`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 道路运输经营许可证
export const uploadRTbusinessLicense = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/ConsInfo/rtbusinessLicense`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

//获取金税盘信息
export const GetUKeyInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/v2/HangXin/GetUKeyInfo`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 保存UKey信息接口
export const SaveUKeyInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/EnterpriseUKey/SaveUKeyInfo`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
