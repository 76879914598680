import axios from '@/api/config/interceptor'
import {
    host
} from '@/api/config/host'
import {
    _paramsToQueryString
} from '@/utils/utils'

// 获取结算信息列表
export const tasksInfoList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/IvoiceTasksInfoList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 汇总开票相关信息
export const taskSummary = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/InvoiceTaskSummary`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 根据ID查看开票相关信息
export const tasksInfoDeatils = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/IvoiceTasksInfoDeatils`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载电子回单
export const downloadBankUrl = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${host}/api/Yumin/DownLoad/BusinessReceipt`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


// 
// 
// 分割线
// 
// 服务商接口

// 查询列表--司机
export const invoiceInfoList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/InvoiceInfoListByDriver`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 汇总开票相关信息
export const ByDriver = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Invoice/InvoiceTaskSummaryByDriver`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 根据ID查看开票相关信息--司机
export const invoiceInfoInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/IvoiceTasksInfoDeatilsByDriver`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查看分成比例
export const commissionInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Commission/GetCommissionInfo`, null, {
            params
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 同意合作分成比例
export const AgreeCommission = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Commission/AgreeCommission`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载结算单模板
export const upExport = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/File/Export`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载人工审核明细
export const ManualDetailListDownload = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBillManual/ManualDetailListDownload`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 图片上传
export const upImg = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${host}/api/Image/Upload/Img`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 上传支付凭证
export const batchUpload = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${host}/api/PayEvidence/BatchUpload`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查看支付凭证
export const allPayEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${host}/api/PayEvidence/All?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除已上传的支付凭证
export const delPayEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.delete(
            `${host}/api/PayEvidence?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 服务商查看平台设置比例历史记录
export const getRecordList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${host}/api/Commission/GetCommissionRecordList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 服务商查看平台历费率记录
export const getRecordDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${host}/api/Commission/GetCommissionRecordDetail?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 平台补签合同
export const reissueContract = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${host}/api/Provider/ReissueElectronicContract`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载合同
export const downloadContract = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${host}/api/Provider/DownloadContractForInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 结算退单
export const ChargebackWaybill = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${host}/api/v2/InvoiceTask/ChargebackWaybill`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 运单列表详情--下载
export const DownLoadIvoiceTasksInfoList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${host}/api/Invoice/DownLoadIvoiceTasksInfoList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 上传发票表格
export const ImportInvoiceTable = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/ImportInvoiceTable`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}