import axios from '@/api/config/interceptor'
import {
    host,
    fileUpload
} from '@/api/config/host'
import {
    _paramsToQueryString
} from '@/utils/utils'

//上传Excel
export const uploadExcel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/File/ExcelPars`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//批量认证司机信息
export const saveDriverInfoList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/SaveDriverInfoList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 批量导入司机
export const DriverExcel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/ExcelPars`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 批量导入车辆
export const VehicleExcel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Vehicle/ExcelPars`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查看草稿箱司机信息
export const driversDraftInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/DriversDraftInfoList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查看草稿箱车辆信息
export const vehiclesDraftInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Vehicle/VehiclesDraftInfoList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 司机存入草稿箱
export const saveDraftDriver = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/SaveDraftDriverList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除草稿箱司机
export const deleteDrivers = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/DeteDriversList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 网络货运平台上传司机表格
export const uploadDriver = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TempTransport/UploadDriver`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 网络货运平台上传车辆表格
export const uploadCar = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TempTransport/UploadCar`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 网络货运平台错误司机导出
export const ExportFailDriver = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TempTransport/ExportFailDriver`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 司机表格导出
export const DwonloadTableDriver = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TempTransport/DwonloadTableDriver`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 网络货运平台错误车辆导出
export const ExportFailCar = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TempTransport/ExportFailCar`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 车辆表格导出
export const DwonloadTableCar = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TempTransport/DwonloadTableCar`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 提交资料前校验资料 -- 导出
export const CheckDriverToExcel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/TempTransport/CheckDriverToExcel`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//通过url上传图片
// export const upLoadImgByUrl = (params) => {
//     return new Promise((resolve, reject) => {
//         axios.post(`${host}/api/TempTransport/upLoadImgByUrl`,params).then(res => {
//             if (res.code == 0) {
//                 resolve(res.data)
//             } else {
//                 reject(res)
//             }
//         })
//     })
// }
export const upLoadImgByUrl = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${fileUpload}/api/FileUpload/Upload`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
//批量实名校验
export const realnameList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Driver/RealnameList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除草稿箱司机
export const DeleteDraft = () => {
    return new Promise((resolve, reject) => {
        axios.delete(`${host}/api/v2/Driver/DeleteDraft`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 从草稿箱移除的数据
export const PutDriverIsTroubleBaseInfoAsync = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Driver/PutDriverIsTroubleBaseInfoAsync`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 提交资料前校验资料
export const CheckDriverToList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/CheckDriverToList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}